<template>
  <div>
    <Account v-if="user.id" :profile="user" />
    <router-view v-if="user" :profile="user" :key="$route.path"></router-view>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import Account from "./Account.vue";
import {
  setCurrentPageBreadcrumbs,
  setCurrentPageTitle
} from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "PageUser",
  components: {
    Account
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const user = ref({});

    const getUser = async () => {
      try {
        if (route.params.user_id) {
          setCurrentPageBreadcrumbs("iprofile", ["user"]);
          user.value = await ApiService.get(
              `/api/users/${route.params.user_id}`
          ).then(({ data }) => data);
          user.value.myself = false;
        } else {
          setCurrentPageTitle("profile");
          user.value = await ApiService.get(`/api/auth/me`).then(
              ({ data }) => data
          );
          user.value.myself = true;
        }
      } catch (error) {
        console.error(error);
      }
    };

    watch(
        () => [route.params.user_id, store.getters.currentUser],
        async ([user_id, user], [oldId, oldUs]) => {
          if (user_id !== oldId || user !== oldUs) {
            await getUser();
          }
        }
    );

    onMounted(() => {
      getUser();
    });

    return {
      user
    };
  }
});
</script>
