
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref, toRef } from "vue";
export default defineComponent({
  name: "KtAccount",
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const permisionsAssigned = ref(0);
    const permissions = toRef(props.profile, "permissions_list");
    const getPerPermissions = () => {
      try {
        ApiService.get("/api/permissions").then(({ data }) => {
          const per = Math.round(
            (permissions.value.length / data.length) * 100
          );
          permisionsAssigned.value = per;
        });
      } catch (err) {
        console.error(err);
      }
    };
    onMounted(() => {
      getPerPermissions();
    });
    return {
      permisionsAssigned,
      sita_path: document.location.origin,
    };
  },
});
